export const Column = [
    {
        prop: "propertyTypeName",
        label: "资源资产类型",
        width: 160,
    },
    {
        prop: "loansLimit",
        label: "申请额度（元）",
    },

    {
        prop: "financingType",
        label: "融资方式",
        width: 160,
    },
    // 暂时不展示
    // {
    //     prop: "createTime",
    //     label: "融资编号",
    //     width: 160,
    // },
    {
        prop: "createdTime",
        label: "提交时间",
        width: 180,
        // template: true,
    },
    {
        prop: "approveStatusName",
        label: "状态",
        width: 160,
    },
    {
        prop: "loansLimit",
        label: "放款额度（元）",
        width: 160,
        template: true,
    },
    {
        prop: "caozuo",
        label: "操作",
        // width: 90,
        template: true,
    },
]
