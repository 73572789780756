<template>
  <!-- 会员中心 -->
  <div class="finished">
    <dbrDialog v-if="dbDialog" />
    <div class="project">
      <h3 class="title">我的融资</h3>
      <div class="table-con" v-loading="loading">
        <table-page
          :data="tableData"
          :columns="columns"
          :currentPage="query.pageIndex"
          :pageSize="query.pageSize"
          :total="total"
          :border="true"
          @sizeChange="handleSizeChange"
          @currentChange="handleCurrentChange"
        >
          <template slot="caozuo" slot-scope="scope">
            <!-- v-if="scope.row.financingType == 3" -->
            <el-button
              type="warning"
              size="small"
              @click="showDbDialog(scope.row)"
              >担保人</el-button
            >
            <el-button
              type="danger"
              size="small"
              @click="delClick(scope.row.orderId)"
              >撤销</el-button
            >
          </template>
        </table-page>
      </div>
    </div>
  </div>
</template>
  
  <script>
import TablePage from "@/components/table/table-page";
import { Column } from "./tableColumn";
import { getMyFinanceList } from "@/api/ApplicationsFor";
import { mapGetters } from "vuex";
// import dbrDialog from "./dbrDialog";
export default {
  components: {
    TablePage,
    // dbrDialog,
  },
  data() {
    return {
      dbDialog: false,
      tableData: [],
      columns: Column,
      loading:false,
      total: 0,
      query: {
        pageIndex: 1,
        pageSize: 10,
      },
      cqlx: [
        {
          name: "四荒地使用权",
          value: 1,
        },
        {
          name: "宅基地使用权",
          value: 2,
        },
        {
          name: "农房使用权",
          value: 3,
        },
        {
          name: "集体建设用地使用权",
          value: 4,
        },
        {
          name: "耕地承包使用权",
          value: 11,
        },
        {
          name: "草地承包使用权",
          value: 12,
        },
        {
          name: "林地承包使用权)",
          value: 13,
        },
      ],
      rzfs: [
        {
          name: "抵押担保",
          value: 1,
        },
        {
          name: "质押担保",
          value: 2,
        },
        {
          name: "找人担保",
          value: 3,
        },
        {
          name: "为人担保",
          value: 4,
        },
      ],
    };
  },
  computed: mapGetters(["userInfo"]),
  mounted() {
    this.getTableData();
  },
  methods: {
    showDbDialog() {
      this.dbDialog = false;
    },
    handleRowClass({ row, rowIndex }) {
      // 行樣式
      if (rowIndex % 2 === 0) {
        return "odd";
      }
    },
    handleCurrentChange(page) {
      this.query.pageIndex = page;
      this.getList();
    },
    handleSizeChange(size) {
      this.query.pageIndex = 1;
      this.query.pageSize = size;
      this.getList();
    },
    async getTableData() {
      this.loading=true;
      let params = {
        pageIndex: this.query.pageIndex,
        pageSize: this.query.pageSize,
        userId: this.userInfo.id,
      };
      let res = await getMyFinanceList(params);
      this.loading=false;
      if (res.status == 200) {
        this.total = res.data.total * 1;
        this.tableData = res.data.records;
      }
      for (var i in this.tableData) {
        // 产权类型
        for (var j in this.cqlx) {
          if (this.tableData[i].propertyType === this.cqlx[j].value) {
            this.tableData[i].propertyType = this.cqlx[j].name;
          }
        }
        // 融资方式
        for (var m in this.rzfs) {
          if (this.tableData[i].financingType === this.rzfs[m].value) {
            this.tableData[i].financingType = this.rzfs[m].name;
          }
        }
      }
    },
    // 改变当前页
    handleCurrentChange(val) {
      this.query.pageIndex = val;
      this.getTableData();
    },
  },
};
</script>
  
  <style lang="less" scoped>
h3 {
  text-indent: 30px;
}
.finished {
  text-align: left;
  height: 100%;
  position: relative;
  // margin-top: 46px;

  .project {
    // margin-top: 58px;
    .table-con {
      margin-top: 12px;
      padding: 0 15px 0 18px;
    }
  }
}
</style>